<template>
  <main>
    <div class="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
      <div class="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h1
            class="text-3xl mb-12 leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
          >
            CBD Research Library
          </h1>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            With everything that is being said about what CBD can do, how much
            of it is actually true? Our goal is to provide everyone with the
            actual research that has been conducted involving Cannabidiol. We
            try to keep our research library as up-to-date as possible with the
            new discoveries and effects of CBD so make sure to check back often
            for any new findings.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            CBDCOAs is not making any claims in regard to the effects of CBD
            oil. All of the information presented here comes from and links to
            the United States National Library of Medicine's website. We have
            compiled this information together into an easy-to-navigate research
            library with the sole intention of providing our visitors with
            peer-reviewed research and studies so that they can make an
            informed, educated decision; we are not attempting to sway your
            opinion in either direction.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Want to learn how CBD works with different diseases or conditions?
            Our research articles are separated by conditions so you can read
            multiple research articles about conditions that are important to
            you.
          </p>
        </div>

        <div class="mt-12 pt-12 border-t-2 border-gray-100 ">
          <label for="filter" class="sr-only">Search research articles</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="filter"
              v-model="search"
              class="form-input block w-full sm:text-sm sm:leading-5"
              placeholder="Search research articles"
              aria-describedby="filter-input"
            />
          </div>
        </div>
        <div
          v-if="articles.length"
          class="grid gap-16 justify-center pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12"
        >
          <router-link
            v-for="link in articles"
            :key="link.path"
            :to="'/cbd-research-library/' + link.path"
            class="block"
          >
            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <p class="text-xl leading-7 font-semibold text-gray-900">
                  {{ link.title }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-else class="mt-2">Loading articles...</div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      search: ""
    };
  },
  computed: {
    articles() {
      return this.$store.state.articles.filter(article => {
        return article.title.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  metaInfo() {
    return {
      title: "CBD Research Library",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Studies and peer-reviewed research into the effects of Cannabidiol (CBD) and different diseases or medical conditions"
        }
      ]
    };
  },
  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.fetchArticles();
  },

  // Client-side only
  mounted() {
    this.fetchArticles();
  },

  methods: {
    fetchArticles() {
      return this.$store.dispatch("fetchAllArticles").catch(() => {
        this.$router.replace("/error");
      });
    }
  }
};
</script>
